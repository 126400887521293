import React from 'react';

// Components
import Layout from '../components/layout';
import Intro from '../components/testimonials/intro';
import Testimonials from '../components/testimonials/testimonials';

import routes from '../constants/routes';

const TestimonialsPage = () => {
    return (
        <Layout slug={routes.testimonials} title="Testimonials">
            <Intro />
            <Testimonials />
        </Layout>
    );
};

export default TestimonialsPage;
