import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from '../../styles/modules/testimonials.module.scss';

const Testimonials = () => {
    const data = useStaticQuery(graphql`
        query {
            allMdx(filter: {fileAbsolutePath: {regex: "/testimonials\\//"}}) {
                edges {
                    node {
                        frontmatter {
                            author
                            location
                            text
                            image {
                                childImageSharp {
                                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    
    return (
        <div className={styles.testimonials}>
            <div className="copy-width">
                <div className={styles.grid}>
                    {
                        data.allMdx.edges.map((edge, index) => {
                            const { author, image, location, text } = edge.node.frontmatter;
                            return (
                                <div key={index} className={styles.card}>
                                    <GatsbyImage className={styles.image} alt={`${author} testimonial image`} image={getImage(image)} />
                                    <div className={styles.content}>
                                        <p>{text}</p>
                                        <p className={styles.byline}>{author}, {location}</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
