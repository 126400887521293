import React from 'react';

import * as styles from '../../styles/modules/testimonials-intro.module.scss';

const Intro = () => {
    return (
        <div className={styles.intro}>
            <h1>Testimonials</h1>
            <div className="copy-width">
                <div className={styles.content}>
                    <p>We are honored to have worked on many great projects over the years. We have built multiple projects for returning customers, and we are incredibly grateful – not just for them, but for their support and referrals. Here's what some of our clients have said about us.</p>
                </div>
            </div>
        </div>
    );
};

export default Intro;
